html {
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  image-rendering: -webkit-optimize-contrast;
  -webkit-animation-duration: 0.1s;
  -webkit-animation-name: fontfix;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 0.1s;
  font-family: Teko, sans-serif;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  overflow-x: hidden!important;
  font-size: 16px;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border-style: none;
}

p {
  margin-top: 0;
}

address {
  font-style: normal;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: #000;
}

a:hover {
  color: #0e834b;
}

input,
button,
textarea,
select {
  font: inherit;
}

input::-ms-clear{
  display: none!important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Marker tweaks */
.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
  margin-top: 15px;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
  border-bottom-color: #91c949;
}

#map-filter-panel::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-left: 0;
  background: rgba(0, 0, 0, 0.1);
}

#map-filter-panel::-webkit-scrollbar-track {
  background: none;
}

#map-filter-panel::-webkit-scrollbar-thumb {
  background: #00853e;
  border-radius: 0;
}


#listings::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-left: 0;
  background: rgba(0, 0, 0, 0.1);
}

#listings::-webkit-scrollbar-track {
  background: none;
}

#listings::-webkit-scrollbar-thumb {
  background: #00853e;
  border-radius: 0;
}
